<template>
  <div class="warp">
    <div class="item-list" v-for="(item, index) in itemList" :key="index">
      <div class="item-s">
        <p><span class="span1">{{item.boss ? item.username + '(负责人)' : item.username}}</span><span class="span2">{{item.userMobile}}</span></p>
        <p class="p2">{{!item.pass ? '未绑定' : '已绑定'}}</p>
      </div>
      <div class="item-x" v-if="isShowbtns">
        <p>申请时间: {{conversionTime(item.createTime)}}</p>
        <div class="btns" v-if="item.isPass === 0">
          <van-button type="default" size="small" @click="agreeClick(item)">同意</van-button>
          <van-button type="default" size="small" @click="refuseClick(item)">拒绝</van-button>
        </div>
        <div class="btns" v-if="item.pass && !item.boss">
          <van-button type="default" size="small" @click="refuseClick1(item)">解绑</van-button>
        </div>
      </div>
      <div class="item-x" v-else>
        <p>申请时间: {{conversionTime(item.createTime)}}</p>
      </div>
    </div>
    <div class="btn" v-if="isShowbtns">
      <van-button round block type="info" @click="applyClick">打开企业码,让员工扫码申请</van-button>
      <van-button round block type="default" @click="goBackClick">返回</van-button>
    </div>
    <div class="tishi">
      <p>解绑说明</p>
      <p>1. 企业负责人在微信端点击解绑按钮后，对应的员工即可解绑；</p>
      <p>2. 企业负责人本人需要解绑的情况，需联系红葫芦工业固废收运平台客服进行人工处理。</p>
    </div>
  </div>
</template>

<script>
import { userList, userband, deluserband, getInfo } from '@/api/notice.js'
import { Toast } from 'vant'
import { Dialog } from 'vant';
export default {
  data () {
    return {
      itemList: [],
      isShowbtns: true,
    }
  },
  created() {
    this.userList()
    this.getInfo()
  },
  methods: {
    getInfo() { // 获取信息个人
      getInfo().then(res => {
        if (res.data.code === 200) {
          if (res.data.data.hsCompanyUser.boss === false) {
            this.isShowbtns = false
          }
        }
      })
    },
    userList() { // 员工绑定 列表
      userList().then(res => {
        console.log(res)
        if (res.data.code === 200) {
          this.itemList = res.data.rows
        }
      })
    },
    applyClick() { // 扫码申请
      this.$router.push({path:'Applicant'})
    },
    agreeClick(item) { // 同意
      Dialog.confirm({
        title: '绑定',
        message: '确认申请绑定吗',
      }).then(() => {
        userband(item.userId).then(res => {
          if (res.data.code === 200) {
            Toast('已同意申请绑定')
            this.userList()
          }
        })
      }).catch(() => {
        Toast('取消同意')
      });
    },
    refuseClick(item) { // 拒绝
      Dialog.confirm({
        title: '拒绝',
        message: '确认拒绝绑定吗',
      }).then(() => {
        deluserband(item.userId).then(res => {
          if (res.data.code === 200) {
            Toast('已拒绝绑定')
            this.userList()
          }
        })
      }).catch(() => {
        Toast('取消拒绝')
      });
    },
    refuseClick1(item) { // 解绑
      Dialog.confirm({
        title: '解绑',
        message: '确认解绑吗',
      }).then(() => {
        deluserband(item.userId).then(res => {
          if (res.data.code === 200) {
            Toast('解绑成功')
            this.userList()
          }
        })
      }).catch(() => {
        Toast('取消解绑')
      });
    },
    goBackClick() {
      this.$router.go(-1)
    }
  }
}

</script>
<style lang="scss" scoped>
.warp {
  .item-list {
    padding: 15px;
    margin-bottom: 7px;
    background-color: #fff;
    .item-s {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        margin-bottom: 10px;
        .span1 {
          font-weight: 600;
          font-size: 20px;
          margin-right: 10px;
        }
        .span2 {
          color: #999;
        }
      }
      .p2 {
        color: #999;
        font-size: 14px;
      }
    }
    .item-x {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        color: #999;
      }
    }
  }
  .tishi {
    padding: 0 30px;
    font-size: 12px;
    line-height: 20px;
  }
}
::v-deep .van-button--small {
  margin-left: 5px;
}
::v-deep .van-button--round {
  border-radius: 3px;
}
::v-deep .van-button--info {
  background-color: #0087f0;
  border: 1px solid #0087f0;
  margin-bottom: 15px;
}
</style>
